<template>
  <el-container class="layout-container-demo" style="position:absolute;top: 0;left: 0; height: 100%; width: 100%;border: 1px solid #eee;overflow: hidden;">
    <el-aside width="250px" style="background-color: rgb(238, 241, 246); overflow: visible; ">
      <div style="height: 120px">
        <img :src="sysLogoUrl" style="width: 250px; height: 120px;" />
      </div>
      <el-scrollbar :style="{'height':windowHeight +'px'}">
        <!--导航菜单-->
        <el-menu :default-active="$route.path" class="el-menu-vertical-demo" router style="background-color: white" >
          <template v-for="(item, index) in this.router" :key="index">
            <template v-if="checkMenuShow(item)">
              <!-- 多级节点 -->
              <el-submenu :index="index + ''" v-if="!item.leaf">
                <template v-slot:title>
                  <i style="margin-right: 6px" :class="item.iconCls"></i>
                  <span style="font-size: 18px;">{{item.name}}</span>
                </template>

                <!-- 二级节点 -->
                <template v-for="child in item.children" :key="child.path">
                  <template v-if="checkMenuShow(child)">
                   <template v-if="duplicateRemoval(child)">
                    <el-menu-item-group>
                      <el-menu-item :index="child.path" style="padding-left: 0px">
                        <div :class="[$route.path == child.path ? 'divfff' : '']">
                          <div style="width: 171px;padding-left: 48px;font-size: 16px;" :class="[$route.path == child.path ? 'fff' : '']">
                            {{ child.name }}
                          </div>
                        </div>
                      </el-menu-item>
                    </el-menu-item-group>
                    </template>
                  </template>

                  <!-- 三级节点 -->
                  <template v-if="checkThirdMenuShow(child)">
                    <el-submenu index="index + ''">
                      <template v-slot:title >
                        <span style="font-size: 16px">{{ child.name }}</span>
                      </template>
                      <el-menu-item-group><!--add Lyre-->
                        <el-menu-item v-for="child2 in child.children" :index="child2.path" :key="child2.path" style="padding-left: 0px">
                          <div :class="[$route.path == child2.path ? 'divfff' : '']">
                            <div style="width: 171px;padding-left: 45px;font-size: 16px;" :class="[$route.path == child2.path ? 'fff' : '']">
                              {{ child2.name }}
                            </div>
                          </div>
                        </el-menu-item>
                      </el-menu-item-group>
                    </el-submenu>
                  </template>

                </template>

              </el-submenu>

              <!-- 一级节点 -->
              <el-menu-item v-if="item.leaf && item.children.length > 0" :index="item.children[0].path">
                <i :class="item.iconCls" style="margin-right: 5px"></i>
                <span style="font-size: 18px; ">{{
                  item.children[0].name
                }}</span>
              </el-menu-item>
              
            </template>
          </template>
        </el-menu>
      </el-scrollbar>
    </el-aside>

    <el-container>
      <el-header>
        <div class="top-title">
          {{sysName}}
          <span style="color:red">{{version}}</span>
        </div>
        
        <div class="toolbar">
          <span>{{ unitName }}</span>
          <span>({{ roleName  }})</span>
          <span style="margin:0px 10px">|</span>
          <div style="margin-left: 5px;cursor: pointer;" @click="goBack()">
              <i class="el-icon-switch-button el-i"></i>退出
          </div>
        </div>
      </el-header>

      <el-main style="background-color: var(--el-background-color-base)">
        <el-scrollbar class="appview">
          <router-view></router-view>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// import { getCustomerInfo } from '../api/api'
import session from "../store/store";
import until from '../until/until'
export default {
  data() {
    return {
      router: [],
      accountTypeSrc: "登陆人", //登陆人
      unitName:"", //机构
      role: 0, //角色名称
      sysName:"",
      sysLogoUrl:'',
      roleName:'',
      version:'',
      windowHeight: document.body.clientHeight-120,   //实时屏幕高度
    };
  },
  watch: {
      windowHeight (val) {
        console.log("实时屏幕高度：",val, this.windowHeight );
      },
  },
  created() {
    console.log(document.body.clientHeight)
    if (until.isNULL(session.getToken())) {
      if (this.$route.path != "/login") {
        this.$router.push("/login");
      }
    } else {
      this.getRouter(); //获取router
      this.getLoginInfo(); //获取登录信息
    }
  },
  mounted() { 
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
        return (() => {
          this.windowHeight = document.body.clientHeight-120  //实时屏幕高度

        })()
      };
  },
  methods: {

    setSysInfo(data) {
      this.sysLogoUrl = data.sysLogoUrl;
      this.sysName = data.sysDisplayData.sysName;
      if (until.isNULL(this.sysLogoUrl)) {
        this.sysLogoUrl = require('../assets/img/ls.png');
      }
    },
    /**
     * 获取登录信息
     */
    getLoginInfo() {
      this.accountTypeSrc = session.getLoginInfo().accountTypeSrc;
      this.unitName = session.getLoginInfo().unitName;
      this.role = session.getLoginInfo().accountType;
      this.sysLogoUrl = session.getSysLogoUrl();
      this.sysName = session.getSysName();
      if(this.role == 1) {
        this.roleName = "A级"
      }else if(this.role == 2) {
        this.roleName = "B级"
      }
      document.title = this.sysName + '-' + this.unitName 
      
      var host = window.location.host
      if((host.indexOf("localhost") != -1)
      ||(host.indexOf("test.jkez.net") != -1) ){
        this.version = "(V1.00.026)"
      }
    },
    //获取router
    getRouter() {
      this.router = this.$router.options.routes;
    },

    //判断菜单是否显示
    checkMenuShow(item) {
        if(1 == this.role) {//A级
          return !item.hidden&&(item.meta.role.indexOf('A') != -1)
        }else if(2 == this.role) {//B级{
          return !item.hidden&&(item.meta.role.indexOf('B') != -1)
        }else {
          return !item.hidden&&(item.meta.role.indexOf('C') != -1)
        }
    },

    checkThirdMenuShow(item) {
      return !item.hidden && (item.children != undefined)
    },

    //去重
    duplicateRemoval(item) {
      return item.children == undefined
    },

    goBack() {
      session.clearAll();
      this.$router.push("/login")
    },
  },
};
</script>
<style scoped>
.header {
  height: 75px;
  text-align: center;
}
.headName {
  margin-top: 23px;
  position: relative;
  display: flex;
}
.adminName {
  position: absolute;
  right: 10px;
  color: #444958;
  font-size: 14px;
  display: flex;
  align-items: baseline;
}
.supp {
  position: absolute;
  right: 45%;
}
.layout-container-demo .el-header {
  position: relative;
  background-color: white;
  color: var(--el-text-color-primary);
  box-shadow: 10px 1px 13px -4px #d1d1d1;
}
.layout-container-demo .el-aside {
  width: 250px;
  color: var(--el-text-color-primary);
  background: #fff !important;
  border-right: solid 1px #e6e6e6;
  box-sizing: border-box;
}
.layout-container-demo .el-menu {
  border-right: none;
}
.layout-container-demo .el-main {
  padding: 0;
}
.layout-container-demo .toolbar {
  position: absolute;
  display: inline-flex;
  align-items: center;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.el-menu-item {
  text-align: center;
}
.el-menu-item.is-active {
  color: #285df0;
}
.el-submenu {
  text-align: center;
}
.layout-container-demo .top-title{
  font-size: 20px;
  position: absolute;
  left: 45%;
  top: 28%;
  font-weight: bold;
}
</style>
<style>
.el-scrollbar__wrap {
  overflow: auto !important;
  overflow-x: hidden;
  margin: 0px !important;
}
.el-container {
  background-color: #f9f9f9;
}
.el-menu-item-group__title {
    padding: 3px 0 3px 20px;
}
</style>